import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import * as Icon from 'feather-icons-react';
import { SERVICE_DETAILS } from '../constants';
import API from '../services/API';
import { serviceAction } from '../store/service-slice';
import { useDispatch } from 'react-redux';

const Sidebar = () => {
  const [services, setServices] = useState([]);
  const [menuState, setMenuState] = useState({});
  const location = useLocation();

  const dispatch = useDispatch();
  const toggleMenuState = (menuKey) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuKey]: !prevState[menuKey],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.get(SERVICE_DETAILS);
      if (response.status === 200) {
        setServices(response.data);
      }
    };

    fetchData();
    onRouteChanged();

    // Add hover-open effect for sidebar items
    const body = document.querySelector('body');
    const navItems = document.querySelectorAll('.sidebar .nav-item');
    navItems.forEach((el) => {
      el.addEventListener('mouseover', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

    return () => {
      // Clean up event listeners on component unmount
      navItems.forEach((el) => {
        el.removeEventListener('mouseover', null);
        el.removeEventListener('mouseout', null);
      });
    };
  }, []);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    setMenuState({});

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/general', state: 'generalMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevState) => ({
          ...prevState,
          [obj.state]: true,
        }));
      }
    });
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="sidebar sidebar-offcanvas sidebar-boxshadow" id="sidebar">
      <ul className="nav">
        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/dashboard">
            <Icon.Home className="menu-icon" />
            <span className="menu-title">Home</span>
          </Link>
        </li>
       

        {/* <li className={isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
          <div
            className={menuState.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('appsMenuOpen')}
            data-toggle="collapse"
          >
            <Icon.Layers className="menu-icon" />
            <span className="menu-title">Application Configuration</span>
            <i className="menu-arrow"></i>
          </div>

          <Collapse in={menuState.appsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              {services.length !== 0 &&
                services.map((item) => (
                  <li className="nav-item" key={item.url} onClick={() => dispatch(serviceAction.setService(item))}>
                    <Link
                      className={isPathActive(item.url) ? 'nav-link active' : 'nav-link'}
                      to={item.url}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </Collapse>
        </li> */}

        <li className={isPathActive('/general') ? 'nav-item active' : 'nav-item'}>
          <div
            className={menuState.generalMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('generalMenuOpen')}
            data-toggle="collapse"
          >
            <Icon.Tool className="menu-icon" />
            <span className="menu-title">General Configuration</span>
            <i className="menu-arrow"></i>
          </div>

          <Collapse in={menuState.generalMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={isPathActive('/general/location') ? 'nav-link active' : 'nav-link'}
                  to="/general/location"
                >
                  Entity Hierarchy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/general/workactivity') ? 'nav-link active' : 'nav-link'}
                  to="/general/workactivity"
                >
                  Work Activities
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className={isPathActive('/general/department') ? 'nav-link active' : 'nav-link'}
                  to="/general/department"
                >
                  Department
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link
                  className={isPathActive('/general/designation') ? 'nav-link active' : 'nav-link'}
                  to="/general/designation"
                >
                  Designation
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/general/workinggroup') ? 'nav-link active' : 'nav-link'}
                  to="/general/workinggroup"
                >
                  Working Group
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link
                  className={isPathActive('/general/contractor') ? 'nav-link active' : 'nav-link'}
                  to="/general/contractor"
                >
                  Contractor Role
                </Link>
              </li> */}
            </ul>
          </Collapse>
        </li>

        <li className={isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
          <div
            className={menuState.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('usersMenuOpen')}
            data-toggle="collapse"
          >
            <Icon.User className="menu-icon" />
            <span className="menu-title">User Management</span>
            <i className="menu-arrow"></i>
          </div>

          <Collapse in={menuState.usersMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/user') ? 'nav-link active' : 'nav-link'}
                  to="/users/user"
                >
                 Enterprise AD Users
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/internal-user') ? 'nav-link active' : 'nav-link'}
                  to="/users/internal-user"
                >
                  Internal Users
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/app-user') ? 'nav-link active' : 'nav-link'}
                  to="/users/app-user"
                >
                  External Users
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/general-user') ? 'nav-link active' : 'nav-link'}
                  to="/users/general-user"
                >
                  General Users
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/master-user') ? 'nav-link active' : 'nav-link'}
                  to="/users/master-user"
                >
                  Masterlist of Users
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/project-user') ? 'nav-link active' : 'nav-link'}
                  to="/users/project-user"
                >
                  Application Assignment
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/users/role-user') ? 'nav-link active' : 'nav-link'}
                  to="/users/role-user"
                >
                  Assignment Report
                </Link>
              </li>
             
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/actions') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/actions">
            <Icon.Target className="menu-icon" />
            <span className="menu-title">Activity Log</span>
          </Link>
        </li>
        <li className={isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/logout">
            <Icon.LogOut className="menu-icon" />
            <span className="menu-title">Logout</span>
          </Link>
        </li>

        {/* <li
          className="nav-item"
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            fontSize: 10,
            textAlign: 'center',
            background: '#fff',
          }}
        >
          <p className="menu-title m-0">
            <p style={{ marginBottom: '-10px' }}>POWERED BY </p>
            <img
              src={require('../../assets/images/acuizen-new-logo.png')}
              style={{ width: 120 }}
              alt="test"
            />
          </p>
        </li> */}
      </ul>
    </nav>
  );
};

export default Sidebar;
