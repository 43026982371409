import React from "react";

const CardOverlay = (props) => {

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">{props.title}</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div>
                                            {props.children}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardOverlay;