import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import CRUDTab from './CRUDTab';
import CardOverlay from './CardOverlay';

const Group = () => {
    return (
        <CardOverlay>
            <Router>
                <h4>Group Management (Notifications)</h4>
                <div className="mt-3">
                    <ul className="nav nav-pills mb-3">
                        <li className="nav-item">
                            <Link className="nav-link" to="/insurance">Insurance</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/third-party">Third Party</Link>
                        </li>
                    </ul>

                    <Switch>
                        <Route path="/insurance">
                            <CRUDTab group="Insurance" />
                        </Route>
                        <Route path="/third-party">
                            <CRUDTab group="Third Party" />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </CardOverlay>
    );
}

export default Group;