import React, { useState, useEffect } from 'react';
import API from '../services/API';
import { GENERAL_GROUP_URL, GENERAL_GROUP_WITH_ID_URL } from '../constants';
import cogoToast from 'cogo-toast';
import { Delete, Edit } from '@material-ui/icons';


const CRUDTab = ({ group }) => {
    const [entries, setEntries] = useState([]);
    const [name, setName] = useState("");
    const [emails, setEmails] = useState([""]);

    const [isEditing, setIsEditing] = useState(false);
    const [currentEditId, setCurrentEditId] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const response = await API.get(GENERAL_GROUP_URL)
            setEntries(response.data.filter(entry => entry.group === group));
        }

        fetchData();
    }, [group]);

    const handleEdit = (entry) => {
        setName(entry.name);
        setEmails(entry.email.map(emailObj => emailObj.address));
        setIsEditing(true);
        setCurrentEditId(entry.id);
    };

    const handleSubmit = async () => {
        const newEntry = {
            name,
            email: emails.map(address => ({ address })),
            group
        };

        if (isEditing) {
            // Call the update API method here and then update the local entries state
            await API.patch(GENERAL_GROUP_WITH_ID_URL(currentEditId), newEntry);
            const updatedEntries = entries.map(entry =>
                entry.id === currentEditId ? { ...entry, ...newEntry } : entry
            );
            setEntries(updatedEntries);
            setIsEditing(false);
            setCurrentEditId(null);
        } else {
            const response = await API.post(GENERAL_GROUP_URL, newEntry);
            setEntries([...entries, response.data]);
        }

        setName('');
        setEmails([""]);
    };


    const handleEmailChange = (index, value) => {
        const newEmails = [...emails];
        newEmails[index] = value;
        setEmails(newEmails);
    }

    const addEmailField = () => {
        setEmails([...emails, ""]);
    }

    const handleRemoveEmail = (indexToRemove) => {
        if (emails.length === 1) {
            cogoToast.error("At least one email is required.");
            return;
        }

        const newEmails = emails.filter((_, index) => index !== indexToRemove);
        setEmails(newEmails);
    };
    return (
        <div>
            <h2>{group}</h2>

            {/* Form for creating new entries */}
            <form className="mb-3 w-25" onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
                <div className="mb-3">
                    <label>Enter Group Name</label>
                    <input className="form-control" value={name} onChange={e => setName(e.target.value)} placeholder="Group Name" />
                </div>
                <label>Enter Email IDs to this Group</label>
                {emails.map((email, index) => (
                    <div key={index} className="mb-3">

                        <div className="input-group">
                            <input
                                className="form-control"
                                value={email}
                                onChange={e => handleEmailChange(index, e.target.value)}
                                placeholder="Email"
                            />
                            <Delete className='text-danger cursor-pointer' onClick={() => handleRemoveEmail(index)} />

                        </div>
                    </div>
                ))}

                <button className="btn btn-secondary mb-3" type="button" onClick={addEmailField}>Add another email to this group</button>
                <br />
                <button className="btn btn-primary" type="submit">{isEditing ? "Update" : "Submit"}</button>
            </form>

            {/* List of existing entries */}
            <ul className="list-group w-25">
                {entries.map(entry => (
                    <li key={entry.id} className="list-group-item">
                        <strong>{entry.name}</strong>
                       
                        <button className="btn btn-sm btn-outline-secondary ms-2" onClick={() => handleEdit(entry)}>Edit Group</button>
                        <ul>
                            {entry.email.map((emailObj, index) => (
                                <li key={index}>{emailObj.address}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
}


export default CRUDTab;