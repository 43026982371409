import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
    name: 'login',
    initialState: { isLoading: false, service: {} },
    reducers: {
        setService(state, action) {
            state.isLoading = true;
            state.service = action.payload;
        },

    },
});

export const serviceAction = serviceSlice.actions;

export default serviceSlice;
