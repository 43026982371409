import { configureStore } from "@reduxjs/toolkit";

import loginSlice from './login-slice';
import serviceSlice from "./service-slice";

const store = configureStore({
    reducer: {login: loginSlice.reducer,service:serviceSlice.reducer},
});

export default store;
