import React, { Component, useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import API from '../services/API';
import { CURRENT_USER_URL, FILE_DOWNLOAD } from '../constants';
import { useDispatch } from 'react-redux';
import { loginActions } from '../store/login-slice';
import * as Icon from 'feather-icons-react';
import axios from 'axios';
const Navbar = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('')
  const [logo, setLogo] = useState('')
  useEffect(() => {
    getUsersInfo();
    getFetchLogo();
  }, [])

  const getFetchLogo = async () => {
    try {
      const response = await axios.get(FILE_DOWNLOAD(localStorage.getItem('logo')), {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const data = response.data;
      const logoUrl = data // Assuming the API returns an object with a `url` field
      setLogo(logoUrl);

    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  }



  const getUsersInfo = async () => {
    const response = await API.get(CURRENT_USER_URL);
    if (response.status === 200) {
      setUsername(response.data.email)
      dispatch(loginActions.setUser(response.data))
    }
  }
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row navbar-boxshadow bg-white" >
      {/* <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
        <span className="icon-menu"></span>
      </button>
      <Link className="navbar-brand brand-logo" to="/"><img src={require("../../assets/images/logo.png")} className="me-2" alt="logo" /></Link>
      <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
    </div> */}
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
            <h2 className="welcome-text d-flex align-items-center"><img src={logo} className="me-3" alt="logo" style={{ width: 80 }} /><span className="text-black fw-bold"> Digital Operational Workflow Management Platform</span></h2>

          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className='nav-item'>
          <div class="search">
            <span class="fa fa-search"></span>
            <input placeholder="Search " className='form-control' />
          </div>
        </li>
        <li className="nav-item">
          <Icon.Settings className="menu-icon" />
        </li> */}

          {/* <li className="nav-item">


          <Icon.Bell className="menu-icon" />

      
      

        </li> */}
          <li className="nav-item ">
            <img
              src={require('../../assets/images/acuizen-new-logo.png')}
               style={{ width: 120 }}
              alt="test"
            />


          </li>



        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );

}

export default Navbar;
